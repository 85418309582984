import * as echarts from 'echarts';

const componentChart = () => {


    const bilanzsumme = document.getElementById('bilanzsumme')
    if (bilanzsumme) {
        var echartBilanzsumme = echarts.init(bilanzsumme);
        echartBilanzsumme.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Bilanzsumme',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 5152280926, name: 'Bilanzsumme' },
                    ]
                }
            ]
        });
    }


    if (bilanzsumme) {
        window.onresize = function () {
            echartBilanzsumme.resize();
        };
    }



    const kreditnachfragen = document.getElementById('kreditnachfragen')
    if (kreditnachfragen) {
        var echartKreditnachfragen = echarts.init(kreditnachfragen);
        echartKreditnachfragen.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'vergebene Kredite',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 4075194838, name: 'vergebene Kredite' },
                    ]
                }
            ]
        });
    }

    if (kreditnachfragen) {
        window.onresize = function () {
            echartKreditnachfragen.resize();
        };
    }



    const kundeneinlagen = document.getElementById('kundeneinlagen')
    if (kundeneinlagen) {
        var echartKundeneinlagen = echarts.init(kundeneinlagen);
        echartKundeneinlagen.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Kundeneinlagen',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 2300899065.96, name: 'Kundeneinlagen' },
                    ]
                }
            ]
        });
    }

    if (kundeneinlagen) {
        window.onresize = function () {
            echartKundeneinlagen.resize();
        };
    }





    const kernkapitalquote = document.getElementById('kernkapitalquote')
    if (kernkapitalquote) {
        var echartKernkapitalquote = echarts.init(kernkapitalquote);
        echartKernkapitalquote.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Kernkapitalquote',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 2300899065.96, name: 'Kernkapitalquote' },
                    ]
                }
            ]
        });
    }

    if (kernkapitalquote) {
        window.onresize = function () {
            echartKernkapitalquote.resize();
        };
    }


};

export default componentChart;