import { CountUp } from "countup.js";

const componentCounterGrid = () => {
	const counterGrid = document.querySelector(".counter-grid");

	if (counterGrid) {
		//Countupjs Counter Grid

		const counterGridNumbers = document.querySelectorAll('.counter-grid__number');

		counterGridNumbers.forEach(function (counterGridNumber) {
			const countGridOptions = {
				separator: ".",
				duration: 2.5,
			};

			const countGridOptionsDecimal = {
				decimalPlaces: 2,
				decimal: ',',
				separator: ".",
				duration: 2.5,
			};
			const countGridOptionsDecimalOne = {
				decimalPlaces: 1,
				decimal: ',',
				separator: ".",
				duration: 2.5,
			};

			const countGridOptionsNumber = {
				separator: "",
				duration: 2.5,
			};

			let counterGridData = counterGridNumber.dataset.number;
			let counterGridDataMode = counterGridNumber.dataset.mode;

			const observeOptions = {
				root: null,
				threshold: 0.75,
			};

			if (counterGridDataMode == "decimal") {
				let countUpDecimal = new CountUp(counterGridNumber, counterGridData, countGridOptionsDecimal);
				const observerGridCounterDecimal = new IntersectionObserver(function (entries, observer) {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							return;
						} else {
							countUpDecimal.start();
							observerGridCounterDecimal.unobserve(entry.target);
						}
					});
				}, observeOptions);

				observerGridCounterDecimal.observe(counterGridNumber);

			} 
			else if (counterGridDataMode == "decimalone") {
				let countUpDecimal = new CountUp(counterGridNumber, counterGridData, countGridOptionsDecimalOne);
				const observerGridCounterDecimal = new IntersectionObserver(function (entries, observer) {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							return;
						} else {
							countUpDecimal.start();
							observerGridCounterDecimal.unobserve(entry.target);
						}
					});
				}, observeOptions);

				observerGridCounterDecimal.observe(counterGridNumber);

			}
			else if (counterGridDataMode == "number") {
				let countUpNumber = new CountUp(counterGridNumber, counterGridData, countGridOptionsNumber);
				const observerGridCounterNumber = new IntersectionObserver(function (entries, observer) {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							return;
						} else {
							countUpNumber.start();
							observerGridCounterNumber.unobserve(entry.target);
						}
					});
				}, observeOptions);

				observerGridCounterNumber.observe(counterGridNumber);
			} else {
				let countUp = new CountUp(counterGridNumber, counterGridData, countGridOptions);
				const observerGridCounter = new IntersectionObserver(function (entries, observer) {
					entries.forEach((entry) => {
						if (!entry.isIntersecting) {
							return;
						} else {
							countUp.start();
							observerGridCounter.unobserve(entry.target);
						}
					});
				}, observeOptions);

				observerGridCounter.observe(counterGridNumber);
			}
		});
	}
};

export default componentCounterGrid;
