import { CountUp } from "countup.js";

const componentCounterStart = () => {
	const countYear = document.querySelector("#year");

	if (countYear) {
        const countOptions = {
            startVal: 1790,
            separator: "",
            duration: 4,
        };

        let counterGridData = countYear.dataset.number;
    
        const countUp = new CountUp(countYear, counterGridData, countOptions);
        countUp.start();
	}
};

export default componentCounterStart;
